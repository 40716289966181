var site = site || {};
var generic = generic || {};

(function ($) {
  $(document).on('product.init', function () {
    $(document).trigger('replenishment_select');
  });

  Drupal.behaviors.productReplenishmentSelect = {
    attach: function (context) {
      // Add replenishment menu event if enable_replenishment=true in config.
      if (!Drupal.settings.product_display || !Drupal.settings.product_display.has_replenishment) {
        return null;
      }

      $(document).trigger('replenishment_select', context);
    }
  };

  $(document).on('replenishment_select', function (event, context) {
    context = typeof context === 'undefined' ? this : context;
    var isMobile = parseInt($.cookie('client.isMobile'));
    var $addBtn = $('.js-add-to-cart', '.js-product');
    var $replenishmentRadio = $('input.js-og-replenishment', context);
    var $replenishmentRadioNoReplenishment = $('input.js-og-replenishment[value=0]', context);
    var $replenishmentRadioReplenishment = $('input.js-og-replenishment[value=1]', context);
    var $replenishmentSelect = $('select.js-replenishment-select', context);
    var $replenishmentInfoButton = $('.js-product-replenishment-select-info-icon', context);

    // When the replenishment select changes, update the add-to-bag
    // button's data-replenishment value.
    $replenishmentSelect.once().on('change', function () {
      $($replenishmentRadioReplenishment).prop('checked', true);
      $addBtn.data('replenishment', this.value);
    });

    // Update the add-to-bag button data if a user clicks the label or radio button
    $.merge($replenishmentRadio, $replenishmentRadio.parent()).once().click(function () {
      var $clickedInput = $(this).find('input');

      if (!$clickedInput.is(':checked')) {
        $(this).find('input').prop('checked', true);
        if ($replenishmentRadioReplenishment.is(':checked')) {
          $addBtn.data('replenishment', $replenishmentSelect.val());
        } else {
          $addBtn.data('replenishment', 0);
        }
      }
    });

    $replenishmentInfoButton.once().click(function () {
      var rendered = site.template.get({
        name: 'product_replenishment_info_v1'
      });

      generic.overlay.launch({
        content: rendered,
        height: 510,
        width: 960
      });
    });
  });
})(jQuery);
